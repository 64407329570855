<template>
  <section class="hero is-fullheight" id="page404">
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column has-text-centered">
            <h1 class="title">
              Al parecer esta ruta que estás intentando consultar no existe
            </h1>
            <h2 class="subtitle">
              Puedes regresar al inicio en el siguiente enlace
            </h2>
              <router-link class="button" :to="{ name: 'dashboard' }">
                Regresar
              </router-link>
          </div>
          <!-- <div class="column">
            Poner Imagen chistosa aquí
          </div> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'ErrorPage'
}
</script>

<style scoped>
  #page404 {
    background-color: #292A30;
  }
  .title, .subtitle, .link {
    color: #FFFFFF;
    margin-bottom: 20px;
  }
</style>
